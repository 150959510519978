<template>
  <transition name="fade">
    <div
      v-show="isVisible"
      class="cookie-settings"
    >
      <form @submit.prevent="submitForm">
        <div class="cookie-settings__title title title--5">
          {{ $t('cookie-policy.title') }}
        </div>

        <div class="cookie-settings__message mb-quarter">
          {{ $t('cookie-policy.content') }}
        </div>

        <collapse-transition>
          <ul
            v-if="isOptionsSelectorVisible"
            class="cookie-settings__options"
          >
            <li
              v-for="option in options"
              :key="`cookie-settings-${option.id}`"
              class="cookie-settings__options__item"
            >
              <CheckboxField
                :id="`cookie-settings-checkbox-${option.id}`"
                v-model="values[option.id]"
                :disabled="!option.enabled"
              >
                {{ option.title }}
              </CheckboxField>
            </li>
          </ul>
        </collapse-transition>

        <button
          type="submit"
          class="button button--medium button--secondary button--flush mt-quarter"
        >
          {{ isOptionsSelectorVisible ? $t('cookie-policy.save-settings') : $t('cookie-policy.accept-cookies') }}
          <svg-icon
            icon-name="check"
            icon-size="m"
          />
        </button>

        <collapse-transition>
          <div v-if="!isOptionsSelectorVisible">
            <button
              type="button"
              class="button button--small button--naked button--flush mt-eighth"
              @click="showOptions"
            >
              {{ $t('cookie-policy.show-options') }}
            </button>
          </div>
        </collapse-transition>
      </form>
    </div>
  </transition>
</template>

<script>
import { getCookie, setCookie, doesCookieExist, normalizeCookieValue } from '../utils/cookies'

const DEFAULT_COOKIE_NAME = 'allee-cookie-consents'
const COOKIE_EXPIRATION_DAYS = 365

export default {

  props: {
    cookieName: { type: String, default: DEFAULT_COOKIE_NAME }
  },

  data () {
    return {
      isVisible: false,
      isOptionsSelectorVisible: false,
      options: [
        { id: 'site', title: this.$t('cookie-policy.options.site'), enabled: false },
        { id: 'marketing', title: this.$t('cookie-policy.options.marketing'), enabled: true }
      ],
      values: { site: true, marketing: true }
    }
  },

  mounted () {
    this._getValuesFromCookie()
    this._setVisiblity()
    this._applyTrackingPreferences()
  },

  methods: {
    submitForm () {
      this._saveValuesToCookie()
      this._setVisiblity()
      this._applyTrackingPreferences()
    },

    showOptions () {
      this.isOptionsSelectorVisible = true
    },

    _saveValuesToCookie () {
      setCookie(this.cookieName, new URLSearchParams(this.values).toString(), COOKIE_EXPIRATION_DAYS)
    },

    _getValuesFromCookie () {
      if (!doesCookieExist(this.cookieName)) { return }
      const values = {}
      for (const [key, val] of (new URLSearchParams(getCookie(this.cookieName))).entries()) {
        values[key] = normalizeCookieValue(val)
      }
      this.values = values
    },

    _setVisiblity () {
      this.isVisible = !doesCookieExist(this.cookieName)
    },

    _applyTrackingPreferences () {
      if (!doesCookieExist(this.cookieName) || !this.values?.marketing) {
        return
      }

      (window.dataLayer || {}).push({ event: 'grant_consent' })
    }
  }

}
</script>
