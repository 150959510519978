<template>
  <svg
    class="icon"
    :class="`icon--${iconSize}`"
    viewBox="0 0 24 24"
  >
    <use :xlink:href="`/assets/images/icons/${iconName}.svg#icon`" />
  </svg>
</template>

<script>
export default {

  name: 'SvgIcon',

  props: {
    iconName: { type: String, default: 'default' },
    iconSize: { type: String, default: 'm' }
  }

}
</script>
