<template>
  <b
    v-show="unread > 0"
    class="navigation-actions__icon__badge"
  >
    {{ unread }}
  </b>
</template>

<script>
import Cookies from 'js-cookie'

export default {

  props: {
    highlighted: { type: Object, default: () => ({}) }
  },

  data: () => ({
    visited: []
  }),

  computed: {
    articles () {
      return (Object.keys(this.highlighted?.data) || [])
    },

    unread () {
      return (this.articles.filter(x => !this.visited.includes(x)) || []).length
    }
  },

  mounted () {
    const articles = JSON.parse(Cookies.get('visited_articles') || '[]')
    articles.push((window.location.pathname || '').substring(1))
    Cookies.set('visited_articles', JSON.stringify(articles || '[]'), { expires: 7 })
    this.visited = articles
  }

}
</script>
