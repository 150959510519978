<template>
  <transition name="fade">
    <div
      v-if="isOpened"
      class="mobile-navigation"
      @click.self="close"
    >
      <div class="mobile-navigation__inner">
        <button
          type="button"
          class="mobile-navigation__close-button"
          @click="close"
        >
          <svg-icon
            icon-name="cross"
            icon-size="xl"
            class="mobile-navigation__close-button__icon"
          />
          <span class="visually-hidden">{{ $t('modal.close-button') }}</span>
        </button>

        <div class="mobile-navigation__navigation">
          <slot />
        </div>

        <div class="mobile-navigation__actions">
          <slot name="actions" />
        </div>

        <div class="mobile-navigation__account">
          <slot name="account" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {

  data: () => ({
    isOpened: false
  }),

  created () {
    this.$eventBus.$on('OPEN_NAVIGATION_MODAL', this.open)
    this.$eventBus.$on('CLOSE_NAVIGATION_MODAL', this.close)
    this.$eventBus.$on('CLOSE_ALL_MODALS', this.close)
  },

  methods: {
    open () {
      this.$eventBus.$emit('CLOSE_ALL_MODALS')
      this.isOpened = true
    },

    close () {
      this.isOpened = false
    }
  }

}
</script>
