import { defineRule, configure } from 'vee-validate'
import { setLocale, localize } from '@vee-validate/i18n'
import huLabels from '@vee-validate/i18n/dist/locale/hu.json'
import enLabels from '@vee-validate/i18n/dist/locale/en.json'
import AllRules from '@vee-validate/rules'

import AcceptedRule from './validation-rules/accepted'
import ApcodeRule from './validation-rules/apcode'
import PhoneNumberRule from './validation-rules/phonenumber'
import PriceRule from './validation-rules/price'
import TiktokUrlRule from './validation-rules/tiktokurl'

const customLabels = {
  hu: {
    accepted: 'A(z) {field} elfogadása kötelező',
    apcode: 'Az AP kód formátuma nem megfelelő',
    phonenumber: 'A telefonszám-formátum nem megfelelő',
    price: 'Az összeg legalább 0:{minPrice} forint kell legyen',
    tiktokurl: 'A TikTok profil címe nem megfelelő formátumú'
  },
  en: {
    accepted: '{field} needs to be accepted',
    apcode: 'The format of AP code is not valid',
    phonenumber: 'The phone number format is not valid',
    price: 'The amount needs to be at least 0:{minPrice} Forints',
    tiktokurl: 'The format of your TikTok Profile URL is not valid'
  }
}

configure({
  generateMessage: localize({
    hu: { ...huLabels, ...{ messages: { ...huLabels.messages, ...customLabels.hu } } },
    en: { ...enLabels, ...{ messages: { ...enLabels.messages, ...customLabels.en } } }
  })
})

setLocale(window?.Allee?.language || 'hu')

Object.keys(AllRules).forEach(rule => { defineRule(rule, AllRules[rule]) })

defineRule('accepted', AcceptedRule)
defineRule('apcode', ApcodeRule)
defineRule('phonenumber', PhoneNumberRule)
defineRule('price', PriceRule)
defineRule('tiktokurl', TiktokUrlRule)
