export default [

  {
    path: window.Allee.trendm.path,
    redirect: `${window.Allee.trendm.path}/${window.Allee.trendm.steps[0]}`,
    component: () => import('./components/trendm/TrendMRegistrationForm.vue'),
    children: [
      {
        path: window.Allee.trendm.steps[0],
        component: () => import('./components/trendm/MyBookings.vue'),
        name: 'MyBookings'
      },
      {
        path: window.Allee.trendm.steps[1],
        component: () => import('./components/trendm/Timeslots.vue'),
        name: 'Timeslots'
      },
      {
        path: window.Allee.trendm.steps[2],
        component: () => import('./components/trendm/BookingConfirmation.vue'),
        name: 'BookingConfirmation'
      }
    ]
  },

  {
    path: window.Allee.shopMap.path,
    component: () => import('./components/ShopMap.vue')
  }

]
