<template>
  <div
    class="checkbox-field"
    :class="{ 'checkbox-field--disabled': $attrs.disabled }"
  >
    <label
      class="checkbox-field__checkbox"
      :for="inputId"
    >
      <input
        :id="inputId"
        :key="inputId"
        class="checkbox-field__checkbox__input"
        type="checkbox"
        :value="modelValue"
        :disabled="$attrs.disabled"
        :checked="shouldBeChecked"
        @change="updateInput"
      >
      <label
        class="checkbox-field__checkbox__box"
        :for="inputId"
      >
        <svg
          class="checkbox-field__checkbox__tick"
          viewBox="0 0 19 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="none"
            d="M1.97253999,7.14945669 L6.76724873,12.0458384 L17.078001,2.41062612"
            stroke="currentColor"
            stroke-width="3"
          />
        </svg>
      </label>
    </label>
    <label
      class="checkbox-field__label"
      :for="inputId"
    >
      <slot />
    </label>
  </div>
</template>

<script>
import random from 'string-random'

export default {

  props: {
    modelValue: { type: [Boolean, String, Array], default: null },
    id: { type: String, default: '' },
    value: { type: String, default: null },
    trueValue: { type: [Boolean, String], default: true },
    falseValue: { type: Boolean, default: false }
  },

  emits: ['update:modelValue'],

  computed: {
    _uid: () => random(32),

    inputId () {
      return this.id || `checkbox-field-${this._uid}`
    },

    shouldBeChecked () {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value)
      }
      return this.modelValue === this.trueValue
    }
  },

  methods: {
    updateInput (event) {
      const isChecked = event.target.checked

      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('update:modelValue', newValue)
      } else {
        this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }

}
</script>
