import axios from 'axios'

const api = axios.create({
  baseURL: '/internal-api/v2/',
  headers: {
    'X-CSRF': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
})

export default api
