<template>
  <collapse-transition>
    <div
      v-if="isOpened"
      class="alert-bar"
      :class="`alert-bar--${type}`"
    >
      <div class="alert-bar__container container container--huge">
        <div class="alert-bar__icon">
          <svg-icon
            :icon-name="type === 'warning' ? 'alert' : 'info'"
            icon-size="xxl"
          />
        </div>
        <div class="alert-bar__content">
          <strong class="alert-bar__title">
            <slot name="title" />
          </strong>
          <p class="alert-bar__description">
            <slot name="description" />
          </p>
        </div>
        <div class="alert-bar__button">
          <button
            class="button button--secondary button--small"
            type="button"
            @click="close"
          >
            {{ $t('alert-bar.close-button') }}
            <svg-icon
              icon-name="cross"
              icon-size="m"
            />
          </button>
        </div>
      </div>
    </div>
  </collapse-transition>
</template>

<script>
import Cookies from 'js-cookie'

const COOKIE_EXPIRATION_TIME = 1 // in days
const COOKIE_NAME = 'alert-bar-closed'

export default {

  props: {
    uid: { type: String, default: '' },
    type: { type: String, default: 'info' }
  },

  data () {
    return {
      isOpened: Cookies.get(COOKIE_NAME) !== this.uid
    }
  },

  methods: {
    close () {
      Cookies.set(COOKIE_NAME, this.uid, { expires: COOKIE_EXPIRATION_TIME })
      this.isOpened = false
    }
  }

}
</script>
