import axios from 'axios'
import InternapApi from '../../../internalApi'

const Api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'https://api.allee.hu/v1'
})

export default {

  submitPrizeGameRegistration ({ commit, state, dispatch }, payload) {
    return Api.post('/prize-game/registration', payload)
  },

  submitEventRegistration ({ commit, state, dispatch }, payload) {
    return Api.post('/events/registration', payload)
  },

  submitWorkshopRegistration ({ commit, state, dispatch }, payload) {
    return Api.post('/workshops/registration', payload)
  },

  async getShops ({ commit, state, dispatch }, payload) {
    const languageCode = window.Allee.language || 'hu'
    const params = {}

    if (payload.visibleInShopList) {
      params.visibleInShopList = true
    }

    const response = await InternapApi.get(`/shops/${languageCode}`, { params })
    return response?.data?.data
  },

  async getWorkshops ({ commit, state, dispatch }, payload) {
    const response = await Api.get('/workshops/')
    return response?.data
  }

}
