import * as basicScroll from 'basicscroll'

export default () => {
  window.setTimeout(() => {
    document.querySelectorAll('[data-prlx-root]').forEach(element => {
      basicScroll.create({
        elem: element,
        direct: true,
        track: true,
        from: 'top-bottom',
        to: 'bottom-top',
        props: { '--scroll-position': { from: -50, to: 50 } }
      }).start()
    })
  }, 0)
}
