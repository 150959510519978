function isEmptyObject (value) {
  return value && Object.keys(value).length === 0 && value.constructor === Object
}

export default {
  user: state => state.user,
  isLoggedIn: (state) => {
    return !(isEmptyObject(state.token))
  }
}
