<template>
  <svg
    class="amoeba-lines"
    viewBox="0 0 440 440"
    :style="style"
  >
    <use :xlink:href="`/assets/images/amoebas/lined/${variant}.svg#path`" />
  </svg>
</template>

<script>
export default {

  name: 'AmoebaLines',

  props: {
    variant: { type: [Number, String], default: 1 },
    translateX: { type: [Number, String], default: null },
    translateY: { type: [Number, String], default: null },
    scale: { type: [Number, String], default: null },
    rotate: { type: [Number, String], default: null }
  },

  computed: {
    style () {
      const res = {}

      if (this.translateX || this.translateX?.toString() === '0') {
        res['--translateX'] = this.translateX || 0
      }

      if (this.translateY || this.translateY?.toString() === '0') {
        res['--translateY'] = this.translateY || 0
      }

      if (this.rotate || this.rotate?.toString() === '0') {
        res['--rotate'] = `${this.rotate || 0}deg`
      }

      if (this.scale) {
        res['--scale'] = `${this.scale || '100%'}`
      }

      return res
    }
  }

}
</script>
