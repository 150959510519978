import axios from 'axios'
import InternapApi from '../../../internalApi'

const Api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'https://api.allee.hu/v1'
})

export default {
  async submitRegistration ({ commit, state, dispatch }, payload) {
    return Api.post('/stylist-service/registration', payload)
  },

  async getTimeslots () {
    const response = await InternapApi.get('/trendm/timeslots')
    return response?.data?.data
  },

  async getStylists () {
    const response = await InternapApi.get('/trendm/stylists')
    return response?.data?.data
  },

  async getBookedTimeslots () {
    const response = await Api.get('/stylist-service/booked-timeslots')
    return response?.data?.data
  },

  async getTimeslotsByEmail ({ commit, state, dispatch }, email) {
    const response = await Api.get('/stylist-service/booked-timeslots-by-email', { params: { email } })
    return response?.data?.data
  }
}
