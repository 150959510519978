import Api from '../../../internalApi'

export default {
  login ({ commit, state, dispatch }, payload) {
    return Api.post('/boostcom/login', payload)
      .then(response => {
        commit('SET_AUTH', response.data)
      })
      .then(async loginResponse => {
        const response = await dispatch('getMember')

        if (!response.data) {
          return
        }

        commit('SET_USER', response.data)
      })
  },
  logout ({ commit, state }, payload) {
    return Api.post('/boostcom/logout').then(response => {
      commit('SET_AUTH', {})
      commit('SET_USER', {})
    })
  },
  resetPassword ({ commit, state }, payload) {
    return Api.post('/boostcom/reset-password', payload)
  },
  register ({ commit, state }, payload) {
    return Api.post('/boostcom/members', payload)
  },
  update ({ commit, state }, payload) {
    return Api.put('/boostcom/members', payload)
  },
  getMember ({ commit, state }) {
    return Api.get('/boostcom/member')
  },
  subscribe ({ commit, state }, payload) {
    return Api.post('/boostcom/subscribe', payload)
  }
}
